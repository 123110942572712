<template>
  <!--
    Used to display the InstallatioNConfigs that are used in the videoPostprocessing themes.
  -->
  <div class="installationConfigList">
    <LoadingPlaceholder v-if="loading" />

    <template v-else>
      <Grid
        :ref="kgm_ref"
        :style="{height: 'auto'}"
        :data-items="kgm_computedGridItems(installationConfig)"
        :columns="kgm_responsiveColumns()"
        :filterable="true"
        :filter="kgm_filter"
        :pageable="kgm_pagable"
        :page-size="kgm_take"
        :skip="kgm_skip"
        :take="kgm_take"
        :total="kgm_allGridItems(installationConfig)"
        :sortable="{
          allowUnsort: kgm_allowUnsort,
          mode: kgm_sortMode
        }"
        :sort="kgm_sort"
        selected-field="selected"
        @rowclick="kgm_gridOnRowClick"
        @filterchange="kgm_gridFilterChange"
        @pagechange="kgm_gridPageChange"
        @sortchange="kgm_gridSortChange"
      >
        <div
          slot="nameFilter"
          slot-scope="{props, methods}"
          class="input-group"
        >
          <input
            ref="nameFilterInput"
            :value="props.value"
            type="text"
            class="form-control mr-2 rounded-right"
            placeholder="please enter an installation-name"
            @input="(event) => {methods.change({operator: 'contains', field: props.field, value: event.target.value, syntheticEvent: event});}"
          >
          <div
            v-if="props.value"
            class="input-group-append"
          >
            <button
              class="btn btn-light border rounded"
              @click="kgm_resetInput('nameFilterInput', (event) => {methods.change({operator: '', field: '', value: '', syntheticEvent: event});})"
            >
              <font-awesome-icon
                class="gray"
                icon="times"
              />
            </button>
          </div>
        </div>
        <template
          slot="installationNameTemplate"
          slot-scope="{props}"
        >
          <td :class="props.className">
            <router-link
              :to="`/installation/${ props.dataItem.installationId }`"
              class="alt-primary-color"
            >
              {{ kgm_getNestedValue(props.field, props.dataItem) }}
            </router-link>
          </td>
        </template>
        <template
          slot="optionsTemplate"
          slot-scope="{props}"
        >
          <td :class="props.className">
            <div class="btn-group">
              <button
                class="btn btn-primary btn-sm"
                @click="editInstallationConfig(props.dataItem)"
              >
                <font-awesome-icon
                  class="mr-2"
                  icon="edit"
                />
                <span>{{ $t('edit') }}</span>
              </button>
              <button
                class="btn btn-secondary btn-sm"
                @click="reloadConfig(props.dataItem)"
              >
                <font-awesome-icon
                  class="mr-2 gray"
                  icon="sync-alt"
                />
                <span>Refresh Config</span>
              </button>
              <button
                class="btn btn-secondary btn-sm"
                @click="openConfirmRemoveModal(props.dataItem.id)"
              >
                <font-awesome-icon
                  class="mr-2 gray"
                  icon="trash"
                />
                <span>{{ $t('remove') }}</span>
              </button>
            </div>
          </td>
        </template>
      </Grid>

      <Sidebar
        v-if="showSidebar"
        :show-sidebar="showSidebar"
        :sidebar-width="600"
        @close="refreshAndClose"
      >
        <InstallationConfigListItemAdd
          v-if="addingElement"
          @installationConfigListItemEdit_refreshInstallationConfigList="refreshAndClose()"
        />
        <InstallationConfigListItemEdit
          v-else
          :installation-config-id="currentId"
          :installation-name="currentInstallationName"
          @installationConfigListItemEdit_refreshInstallationConfigList="refreshAndClose()"
        />
      </Sidebar>
    </template>
    
    <SweetModal
      ref="confirmDelete"
      :title="$t('installationConfigListComp.deleteElement')"
      icon="warning"
      blocking
      class="overflowHidden"
    >
      <p>{{ $t('installationConfigListComp.sureToDelete') }}</p>
      <button
        slot="button"
        class="btn btn-secondary float-left mb-3"
        @click="$refs.confirmDelete.close()"
      >
        <font-awesome-icon
          class="mr-2 gray"
          icon="times"
        />
        <span>{{ $t('cancel') }}</span>
      </button>
      <button
        slot="button"
        class="btn btn-danger float-right mb-3"
        @click="removeInstallationConfig()"
      >
        <font-awesome-icon
          class="mr-2"
          icon="trash"
        />
        <span>{{ $t('delete') }}</span>
      </button>
      <div class="clearfix" />
    </SweetModal>
  </div>
</template>

<script>
import Sidebar from '@/components/Base/Sidebar.vue';

import { SweetModal } from 'sweet-modal-vue';
import { kendoGridMixin } from '@/mixins/kendoGridMixin.js';

export default {
  name: "InstallationConfigList",
  components: {
    Sidebar,
    InstallationConfigListItemAdd: () => import('@/components/VideoPostprocessing/InstallationConfig/InstallationConfigListItemAdd.vue'),
    InstallationConfigListItemEdit: () => import('@/components/VideoPostprocessing/InstallationConfig/InstallationConfigListItemEdit.vue'),
    SweetModal
  },
  mixins: [
    kendoGridMixin
  ],
  data () {
    return {
      loading: true,
      installationConfig: null,
      showSidebar: false,
      currentId: null,
      currentInstallationName: null,
      addingElement: false,
      indexToDelete: null,
      kgm_filter: {
        logic: "and",
        filters: [
          {
            field: 'installationName',
            operator: 'contains',
            value: ''
          }
        ]
      },
      kgm_sort: [
        {
          "field": "installationName",
          "dir": "asc"
        }
      ],
      kgm_columns: [
        {
          field: 'installationName',
          filterable: true,
          filter: 'text',
          title: 'Installation Name',
          cell: 'installationNameTemplate',
          filterCell: "nameFilter"
        },
        {
          filterable: false,
          title: 'Options',
          cell: 'optionsTemplate',
          width: '300px'
        }
      ]
    }
  },
  metaInfo () {
    return {
      title: this.$t('installationConfigListComp.installationConfigList')
    }
  },
  created () {
    this.getData();
  },
  methods: {
    //#region Sidebar
    show () {
      this.showSidebar = true;
    },
    close () {
      this.showSidebar = false;
    },
    refreshAndClose () {
      this.getData();
      this.close();
      this.$emit("reloadAuditLogs");
    },
    addInstallationConfig () {
      this.addingElement = true;
      this.show();
    },
    editInstallationConfig (installationConfig) {
      this.currentId = installationConfig.id;
      this.currentInstallationName = installationConfig.installationName;
      this.addingElement = false;
      this.show();
    },
    //#endregion
    
    //#region API-calls
    getData () {
      this.axios.get('/VideoPostprocessing/GetAllInstallationConfig')
        .then((response) => {
          if (response == null) {
            return;
          }
          if (response.data == null) {
            return;
          }
          this.installationConfig = response.data;
        })
        .finally(() => {
          this.loading = false;
        });
    },
    openConfirmRemoveModal (index) {
      this.indexToDelete = index;
      this.$refs.confirmDelete.open();
    },
    removeInstallationConfig () {
      this.axios.delete(`/VideoPostprocessing/RemoveInstallationConfig?id=${ this.indexToDelete }`)
        .then(() => {
          this.$snotify.success(this.$t('installationConfigListComp.successfullyRemoved'));
          this.getData();
          this.$emit("reloadAuditLogs");
        })
        .finally(() => {
          this.indexToDelete = null;
          this.$refs.confirmDelete.close();
        });
    },
    reloadConfig (config) {
      this.axios.post(`/VideoPostProcessing/ReloadConfig?installationId=${ config.installationId }`)
        .then((response) => {
          if (response == null) {
            return;
          }
          if (response.status == null) {
            return;
          }
          if (response.status != 200) {
            return;
          }
          if (response.status == 201) {
              this.$snotify.success("Dualslalom Installation VideoPostProcessing successfully refreshed");
          }
          this.$snotify.success("Installation Installation VideoPostProcessing successfully refreshed");
        });
      }
    //#endregion
  }
}
</script>
<style>
.installationConfigList .k-grid table tr:hover td {
  background :rgb(219, 219, 219) !important;
  cursor: pointer !important;
}
</style>